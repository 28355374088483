// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Testimonials from './pages/Tstimonials';
import DentalImplants from './pages/DentalImplemants';
import Dentures from './pages/Dentures';
import CosmeticDentistry from './pages/CosmaticDentistry';
import OralHygiene from './pages/OralHygiene';
import DentalSurgery from './pages/DentalSurgery';



function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Header/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/dental-implants" element={<DentalImplants />} />
            <Route path="/dentures" element={<Dentures />} />
            <Route path="/cosmetic-dentistry" element={<CosmeticDentistry />} />
            <Route path="/oral-hygiene" element={<OralHygiene />} />
            <Route path="/dental-surgery" element={<DentalSurgery />} />
        </Routes>
        <Footer/>
    </Router>
    // <h1 className="text-3xl font-bold underline text-green-600">
    //   Hello world!
    // </h1>
  );
}

export default App;
