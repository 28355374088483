import React, { useState } from 'react'
import Header from '../components/Header';
import Services from './Services'

const Home = () => {
  // State for form inputs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [serviceType, setServiceType] = useState('');

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
    console.log({ name, email, date, serviceType });
  };
  return (

    <>


      {/* <section
            className="hero spad bg-cover bg-center relative py-20"
            style={{ backgroundImage: 'url(/img/hero-bg-new.jpg)', minHeight: 'calc(100vh - 4rem)'}} // 4rem for h-16 header height
          >
            <div className="absolute inset-0"></div>
            <div className="container mx-auto relative z-10 text-white">
              <div className="row flex items-center justify-center">
                <div className="col-lg-8 text-center">
                  <div className="hero-text">
                    
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center">
                
              </div>
            </div>
          </section> */}





      <section
        className="hero spad bg-cover bg-center relative py-20 flex items-center justify-center w-full"
        style={{
          backgroundImage: 'url(/img/hero-bg-new.jpg)',
          minHeight: 'calc(100vh - 2rem)', // Adjust for header height
          //backgroundSize: 'contain', // Ensures the full image is visible
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundPosition: 'center', // Centers the image
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="container mx-auto relative z-10 text-[#d6589d] text-center px-4">
          {/* Hero Text */}
          <div className="max-w-4xl mx-auto space-y-6">
            <h1 className="text-2xl sm:text-6xl font-extrabold leading-tight text-[#5876d6]">
              <span className="text-[#d6589d]">Welcome to Our  &nbsp;</span> <br />
              Aesthetic of Perfection
            </h1>

            <p className="text-lg sm:text-xl text-gray-200 leading-relaxed">
              Experience premium treatments and personalized care that elevate your
              wellness journey. Your beauty and confidence, redefined.
            </p>

            {/* Call to Action Buttons */}
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <a
                href="/services"
                className="px-5 py-2 bg-[#d6589d] text-white font-semibold rounded-full shadow-lg transform hover:scale-105 transition-all hover:shadow-2xl"
              >
                Explore Our Services
              </a>
              <a
                href="/contact"
                className="px-5 py-2 bg-[#5876d6] text-white font-semibold rounded-full shadow-lg transform hover:scale-105 transition-all hover:shadow-2xl"
              >
                Book an Appointment
              </a>
            </div>
          </div>
        </div>
      </section>







      {/* <!-- Hero Section End --> */}



      <section className="consultation py-12 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Appointment Form */}
            <div className="bg-white shadow-lg rounded-lg p-4">
              <div className="mb-6 text-center">
                <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer">Appointment</h2>
                <span className="text-3xl font-extrabold text-[#5876d6] mt-2">Request for Your</span>

              </div>
              <form action="#">
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full mb-4 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full mb-4 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <div className="relative mb-4">
                  <input
                    type="text"
                    placeholder="Date"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                  <i className="fa fa-calendar absolute right-3 top-3 text-gray-400"></i>
                </div>
                <select
                  className="w-full mb-6 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                >
                  <option value="">Select Type of Service</option>
                  <option value="Advanced equipment">Advanced Equipment</option>
                  <option value="Qualified doctors">Qualified Doctors</option>
                  <option value="Certified services">Certified Services</option>
                  <option value="Emergency care">Emergency Care</option>
                </select>
                <button
                  type="submit"
                  className="w-full hover:bg-[#5876d6] text-white py-3 rounded-lg bg-[#d6589d] transition duration-300"
                >
                  Book Appointment
                </button>
              </form>
            </div>

            {/* Informative Content */}
            <div className="flex flex-col justify-center spaace-y-10">
              {/* Welcome Message */}
              <div className='flex flex-col justify-center items-center'>

                <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer">Welcome to Ivory Smiles</h2>

                <span className="text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer"> Discover Exceptional Care</span>




                <p className="mt-4 text-gray-600">
                  With over 13 years of experience in cosmetic dentistry, Ivory Smiles is dedicated to providing personalized and transformative dental care. Our team of expert dentists combines advanced techniques with a deep understanding of aesthetics to craft beautiful, confident smiles.
                </p>
              </div>

              {/* Video Section */}
              <div className="relative group py-4">
                <div
                  className="rounded-lg bg-cover bg-center h-64"
                  style={{ backgroundImage: `url('img/video.jpg')` }}
                >
                  {/*  <a
              href="https://www.youtube.com/watch?v=PXsuI67s2AA"
              className="absolute inset-0 flex items-center justify-center text-[#5876d6] bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition duration-300"
            > 
              <i className="fa fa-play text-4xl"></i>
            </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* <!-- Consultation Section End --> */}


      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          {/* Header Section */}
          <div className="text-center mb-12">
            <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer">Offer for you</h2>
            <span className=" text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer">Why choose us?</span>

          </div>

          {/* Cards Section */}
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card Items */}
            {chooseUsData.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition duration-300"
              >
                <div className="flex flex-col items-center">
                  {/* Icon/Image */}
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="w-16 h-16 mb-4"
                  />
                  {/* Title */}
                  <h5 className="text-xl font-semibold text-gray-800 mb-2 text-center">{item.title}</h5>
                  {/* Description */}
                  <p className="text-gray-600 text-center">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



      <div>

        <div className='flex justify-center items-center flex-col bg-gray-50'>

          <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer text-center mt-8">Our Special Services</h2>
          <span className="text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer text-center mb-8">Explore our world-class dental services tailored to your needs.</span>

        </div>
        <section
          className="services py-5 bg-cover bg-center bg-black"
          style={{

            backgroundImage: `url('/img/services.jpeg')`,

          }}
        >

          <div className="container mx-auto px-4">


            <div className="text-center">


              {/* <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer">Our Special Services</h2> */}
              {/* <span className=" text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer">Explore our world-class dental services tailored to your needs.</span> */}


            </div>

            {/* <div className="text-center mb-12">
  <div className="bg-white w-full bg-opacity-80 rounded-lg shadow-xl p-8 mx-auto">
    
    <span className="text-3xl font-extrabold text-[#5876d6] mt-4 hover:cursor-pointer block">
      Explore our world-class dental services tailored to your needs.
    </span>
  </div>
</div> */}



            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

              <div className="bg-white shadow-2xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg">
                <div className="p-8 text-center">
                  <img src="/img/services/services.png" alt="Cosmetic Dentistry" className="w-20 mx-auto mb-6" />
                  <h4 className="text-2xl font-bold text-gray-800 mb-4">Cosmetic Dentistry</h4>
                  <p className="text-gray-600">
                    Enhance your smile with treatments like teeth whitening, veneers, and complete smile makeovers designed to boost your natural beauty.
                  </p>
                </div>
              </div>


              <div className="bg-white shadow-2xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg">
                <div className="p-8 text-center">
                  <img src="/img/services/treatment.png" alt="Orthodontics" className="w-20 mx-auto mb-6" />
                  <h4 className="text-2xl font-bold text-gray-800 mb-4">Orthodontics</h4>
                  <p className="text-gray-600">
                    Achieve a confident smile with <strong>Invisalign</strong>, <strong>Clear Braces</strong>, and personalized orthodontic solutions.
                  </p>
                </div>
              </div>


              <div className="bg-white shadow-2xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg">
                <div className="p-8 text-center">
                  <img src="/img/services/dental-implant.png" alt="Implants and Restorations" className="w-20 mx-auto mb-6" />
                  <h4 className="text-2xl font-bold text-gray-800 mb-4">Implants and Restorations</h4>
                  <p className="text-gray-600">
                    Restore missing teeth with high-quality implants and restorations for a seamless smile.
                  </p>
                </div>
              </div>


              <div className="bg-white shadow-2xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg">
                <div className="p-8 text-center">
                  <img src="/img/services/checkup.png" alt="Emergency Dental Care" className="w-20 mx-auto mb-6" />
                  <h4 className="text-2xl font-bold text-gray-800 mb-4">Emergency Dental Care</h4>
                  <p className="text-gray-600">
                    Quick and effective care during dental emergencies to alleviate pain and restore oral health.
                  </p>
                </div>
              </div>


              <div className="bg-white shadow-2xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg">
                <div className="p-8 text-center">
                  <img src="/img/services/checkup.png" alt="Dento-Facial Orthopedics" className="w-20 mx-auto mb-6" />
                  <h4 className="text-2xl font-bold text-gray-800 mb-4">Dento-Facial Orthopedics</h4>
                  <p className="text-gray-600">
                    Focused on <strong>facial growth</strong> and <strong>jaw development</strong> to improve oral health and aesthetics.
                  </p>
                </div>
              </div>


              <div className="bg-white shadow-2xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg">
                <div className="p-8 text-center">
                  <img src="/img/services/services.png" alt="Pediatric Dentistry" className="w-20 mx-auto mb-6" />
                  <h4 className="text-2xl font-bold text-gray-800 mb-4">Pediatric Dentistry</h4>
                  <p className="text-gray-600">
                    Gentle and specialized care for your little ones to ensure healthy teeth and a lifetime of confident smiles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>










      {/* <!-- Services Section End --> */}

      {/* <!-- Team Section Begin --> */}
      {/* <section className="team spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="section-title">
                                    <span>Our Team</span>
                                    <h2>Our Expert Doctors</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="team__item">
                                    <img src="img/team/team-1.jpg" alt="" />
                                    <h5>Caroline Grant</h5>
                                    <span>Plastic surgeon</span>
                                    <div className="team__item__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="team__item">
                                    <img src="img/team/team-2.jpg" alt="" />
                                    <h5>Dr. Maria Angel</h5>
                                    <span>Plastic surgeon</span>
                                    <div className="team__item__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="team__item">
                                    <img src="img/team/team-3.jpg" alt="" />
                                    <h5>Nathan Mullins</h5>
                                    <span>Plastic surgeon</span>
                                    <div className="team__item__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          {/* Title Section */}
          <div className="text-center mb-12">


            <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer">Our Team</h2>
            <span className=" text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer">Meet Our Expert Doctors</span>


            <p className="text-gray-600 mt-4 max-w-2xl mx-auto text-xl">
              Our team of highly skilled professionals is dedicated to providing exceptional care and expertise in their fields.
            </p>
          </div>

          {/* Team Members */}
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"> */}
          <div className="flex justify-center items-center">
            {/* Team Member */}
            <div className="team rounded-lg transition-transform transform hover:-translate-y-2">
              <div className="flex justify-center p-6">
                <img
                  className="w-64 h-64 object-cover rounded-full shadow-md"
                  src="img/ivory_Smiles_Dental_Clinic-removebg-preview.png"
                  alt="Dr. Qutubuddin M. Ahmed"
                />
              </div>
              <div className="p-6 text-center">
                <h5 className="text-xl font-semibold text-[#5876d6] hover:text-[#d6589d] hover:cursor-pointer">Dr. Qutubuddin M. Ahmed</h5>
                <span className="text-sm text-[#d6589d] hover:text-[#5876d6] hover:cursor-pointer block mt-2">BDS, MDS<br />(Orthodontic & Dentofacial Orthopedics Consultant Orthodontist)</span>
                <div className="flex justify-center space-x-4 mt-4">
                  <a href="#" className="text-[#5876d6] hover:text-[#d6589d]">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="#" className="text-[#5876d6] hover:text-[#d6589d]">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="#" className="text-[#5876d6] hover:text-[#d6589d]">
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a href="#" className="text-[#5876d6] hover:text-[#d6589d]">
                    <i className="fa fa-dribbble"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* Team Member */}
            {/* <div className="team rounded-lg bg-white shadow-lg transition-transform transform hover:-translate-y-2 hover:shadow-xl">
                <div className="flex justify-center p-6">
                    <img 
                        className="w-64 h-64 object-cover rounded-full shadow-md" 
                        src="img/team/team-2.jpg" 
                        alt="Dr. Maria Angel"
                    />
                </div>
                <div className="p-6 text-center">
                    <h5 className="text-xl font-semibold text-gray-800">Dr. Maria Angel</h5>
                    <span className="text-sm text-lime-500 block mt-2">Plastic Surgeon</span>
                    <div className="flex justify-center space-x-4 mt-4">
                        <a href="#" className="text-gray-600 hover:text-lime-500">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a href="#" className="text-gray-600 hover:text-lime-400">
                            <i className="fa fa-twitter"></i>
                        </a>
                        <a href="#" className="text-gray-600 hover:text-lime-500">
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a href="#" className="text-gray-600 hover:text-lime-500">
                            <i className="fa fa-dribbble"></i>
                        </a>
                    </div>
                </div>
            </div> */}

            {/* Team Member */}
            {/* <div className="team rounded-lg bg-white shadow-lg transition-transform transform hover:-translate-y-2 hover:shadow-xl">
                <div className="flex justify-center p-6">
                    <img 
                        className="w-64 h-64 object-cover rounded-full shadow-md" 
                        src="img/team/team-3.jpg" 
                        alt="Nathan Mullins"
                    />
                </div>
                <div className="p-6 text-center">
                    <h5 className="text-xl font-semibold text-gray-800">Nathan Mullins</h5>
                    <span className="text-sm text-lime-500 block mt-2">Plastic Surgeon</span>
                    <div className="flex justify-center space-x-4 mt-4">
                        <a href="#" className="text-gray-600 hover:text-lime-500">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a href="#" className="text-gray-600 hover:text-lime-400">
                            <i className="fa fa-twitter"></i>
                        </a>
                        <a href="#" className="text-gray-600 hover:text-lime-500">
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a href="#" className="text-gray-600 hover:text-lime-500">
                            <i className="fa fa-dribbble"></i>
                        </a>
                    </div>
                </div>
            </div> */}
          </div>
        </div>
      </section>




      {/* <!-- Team Section End --> */}

      {/* <!-- Gallery Begin --> */}
      {/* <div className="gallery">
                    <div className="gallery__container">
                        <div className="grid-sizer"></div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-1.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-2.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-3.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item gc__item__large set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-4.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-5.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-6.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-7.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                    </div>
                </div> */}


      {/* <!-- Latest News End --> */}

      <div className="bg-gradient-to-r from-blue-600 to-pink-600 text-white rounded-lg px-8 py-12 text-center">
        <h2 className="text-3xl font-bold animate-bounce">Ready to Transform Your Smile?</h2>
        <p className="my-4 text-lg">
          Contact us today for a consultation and take the first step toward a brighter, healthier smile.
        </p>
        <a
          href='/contact'
          className="mt-10 text-[#d6589d] bg-[#5876d6] font-bold py-2 px-6 rounded-full shadow-lg hover:bg-[#d6589d] duration-200 transition">
          Book an Appointment
        </a>

      </div>










      {/* <!-- Gallery End --> */}

      {/* <!-- Latest News Begin --> */}
      {/* <section className="latest spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-6">
                                <div className="section-title">
                                    <span>Our News</span>
                                    <h2>Skin care tips</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="latest__btn">
                                    <a href="#" className="primary-btn">View all news</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="latest__item">
                                    <h5><a href="#">Here’s how you can get a natural glow this party season</a></h5>
                                    <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <ul>
                                        <li><img src="img/blog/blog-author.jpg" alt="" /> John Doe</li>
                                        <li>Dec 06, 2019</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="latest__item">
                                    <h5><a href="#">Get better skin with these top 10 tips for skin care</a></h5>
                                    <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <ul>
                                        <li><img src="img/blog/blog-author.jpg" alt="" /> John Doe</li>
                                        <li>Dec 06, 2019</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="latest__item">
                                    <h5><a href="#">8 Ways to Save Your Skin if You Exercise Outside This Winter</a></h5>
                                    <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <ul>
                                        <li><img src="img/blog/blog-author.jpg" alt="" /> John Doe</li>
                                        <li>Dec 06, 2019</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      <section className="latest py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          {/* Title and Button Row */}
          <div className="flex flex-wrap items-center justify-center mb-12">
            <div className="text-center w-full md:w-auto">
              {/* <h2 className="text-lg text-[#5876d6] mt-2 uppercase tracking-wide font-semibold hover:cursor-pointer text-center"></h2>
                                <h3 className="text-3xl text-[#d6589d]  hover:cursor-pointer font-semibold mt-2 text-center"></h3> */}

              <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer text-center">Testimonials</h2>
              <span className=" text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer text-center">What Our Clients Say</span>

            </div>
          </div>



          {/* Testimonials Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Testimonial 1 */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h5 className="text-xl font-semibold text-gray-800">
                <a href="#" className="hover:text-[#5876d6] transition">
                  “Amazing results and natural glow!”
                </a>
              </h5>
              <p className="text-gray-600 mt-4">
                The team at Ivory Smiles Dental Clinic exceeded my expectations! Their attention to detail and personalized care gave me a smile that looks completely natural and radiant. I couldn’t be happier with the results.
              </p>

              {/* Stars */}
              <div className="flex items-center mt-4">
                {[...Array(5)].map((_, i) => (
                  <i key={i} className="fa fa-star text-blue-500"></i>
                ))}
              </div>
              {/* Author Info */}
              <ul className="flex items-center space-x-4 mt-4 text-gray-500">
                <li className="flex items-center">
                  <img
                    src="img/blog/blog-author.jpg"
                    alt="Author"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  Nagraj Pandey
                </li>
                <li>Dec 06, 2019</li>
              </ul>
            </div>

            {/* Testimonial 2 */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h5 className="text-xl font-semibold text-gray-800">
                <a href="#" className="hover:text-[#5876d6] transition">
                  “Top-notch service with visible improvements!”
                </a>
              </h5>
              <p className="text-gray-600 mt-4">
                From the moment I walked in, I felt at ease. The staff is incredibly welcoming, and the level of expertise displayed throughout my treatment was truly impressive.
              </p>

              {/* Stars */}
              <div className="flex items-center mt-4">
                {[...Array(4)].map((_, i) => (
                  <i key={i} className="fa fa-star text-blue-500"></i>
                ))}
                <i className="fa fa-star text-gray-300"></i>
              </div>
              {/* Author Info */}
              <ul className="flex items-center space-x-4 mt-4 text-gray-500">
                <li className="flex items-center">
                  <img
                    src="img/blog/blog-author.jpg"
                    alt="Author"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  Bhushan Kumar
                </li>
                <li>Jan 15, 2020</li>
              </ul>
            </div>

            {/* Testimonial 3 */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h5 className="text-xl font-semibold text-gray-800">
                <a href="#" className="hover:text-[#5876d6] transition">
                  “Fantastic experience from start to finish!”
                </a>
              </h5>
              <p className="text-gray-600 mt-4">
                The care and precision demonstrated by the team were exceptional. Every step of my treatment was explained thoroughly, and the results speak for themselves. A truly outstanding experience at Ivory Smiles Dental Clinic.
              </p>

              {/* Stars */}
              <div className="flex items-center mt-4">
                {[...Array(5)].map((_, i) => (
                  <i key={i} className="fa fa-star text-blue-500"></i>
                ))}
              </div>
              {/* Author Info */}
              <ul className="flex items-center space-x-4 mt-4 text-gray-500">
                <li className="flex items-center">
                  <img
                    src="img/blog/blog-author.jpg"
                    alt="Author"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  Sanjib Das
                </li>
                <li>Feb 20, 2020</li>
              </ul>
            </div>
          </div>
          <div className='flex justify-center my-5'>
            <a
              href="/testimonials"
              className="bg-[#d6589d] text-white py-2 px-6 rounded-md hover:bg-[#5876d6] transition">
              View all testimonials
            </a>
          </div>
        </div>
      </section>


      <section className="gallery bg-gray-100 py-12">
        <div className="container mx-auto px-4">


          <div className='flex items-center justify-center flex-col  text-center'>

            <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer text-center">Photo Gallery</h2>
            <span className=" text-3xl font-extrabold text-[#5876d6] mt-1 hover:cursor-pointer text-center mb-3">Our team of highly skilled professionals in their fields.</span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {images.map((image, index) => (
              <div
                key={index}
                className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer"
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
                />
                {/* <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <a
                  href={image.src}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-lg font-medium bg-gray-900 px-4 py-2 rounded shadow hover:bg-gray-700"
                >
                  View Full
                </a>
              </div> */}
              </div>
            ))}
          </div>
        </div>
      </section>





    </>
  );
};

const images = [
  { src: '/img/gallery/gallery-1.jpg', alt: 'Gallery Image 1' },
  { src: '/img/gallery/gallery-2.jpg', alt: 'Gallery Image 2' },
  { src: '/img/gallery/gallery-3.jpg', alt: 'Gallery Image 3' },
  { src: '/img/gallery/gallery-2.jpg', alt: 'Gallery Image 2' },
  { src: '/img/gallery/gallery-5.jpg', alt: 'Gallery Image 5' },
  { src: '/img/gallery/gallery-6.jpg', alt: 'Gallery Image 6' },
  { src: '/img/gallery/gallery-7.jpg', alt: 'Gallery Image 7' },
  { src: '/img/gallery/gallery-3.jpg', alt: 'Gallery Image 3' },
];


const chooseUsData = [
  {
    title: 'Advanced Equipment',
    description:
      'We use the latest state-of-the-art dental technology to ensure precise diagnostics and effective treatments, making your visit comfortable and efficient.',
    icon: 'img/icons/ci-1.png',
  },
  {
    title: 'Qualified Doctors',
    description:
      'Our team of experienced and skilled dental professionals are dedicated to providing the highest level of care, tailoring treatments to your specific needs.',
    icon: 'img/icons/ci-2.png',
  },
  {
    title: 'Certified Services',
    description:
      'We adhere to the highest standards in dental care, offering services backed by certifications and a commitment to quality and safety for all our patients.',
    icon: 'img/icons/ci-3.png',
  },
  {
    title: 'Emergency Care',
    description:
      'Dental emergencies can happen at any time. We\'re here to provide fast, reliable emergency dental care whenever you need it most.',
    icon: 'img/icons/injection.png',
  },
  {
    title: 'Affordable Treatment Options',
    description:
      'We offer cost-effective dental solutions without compromising on quality.',
    icon: 'img/icons/pay.png',
  },
  {
    title: 'Patient-Centered Approach',
    description:
      'From the moment you step into our clinic, your comfort and satisfaction are our top priority.',
    icon: 'img/icons/patient.png',
  },
];


const teamData = [
  {
    name: "Caroline Grant",
    role: "Plastic Surgeon",
    image: "img/team/team-1.jpg",
    socials: [
      { icon: "fa-facebook", link: "#" },
      { icon: "fa-twitter", link: "#" },
      { icon: "fa-instagram", link: "#" },
      { icon: "fa-dribbble", link: "#" },
    ],
  },
  {
    name: "Dr. Maria Angel",
    role: "Plastic Surgeon",
    image: "img/team/team-2.jpg",
    socials: [
      { icon: "fa-facebook", link: "#" },
      { icon: "fa-twitter", link: "#" },
      { icon: "fa-instagram", link: "#" },
      { icon: "fa-dribbble", link: "#" },
    ],
  },
  {
    name: "Nathan Mullins",
    role: "Plastic Surgeon",
    image: "img/team/team-3.jpg",
    socials: [
      { icon: "fa-facebook", link: "#" },
      { icon: "fa-twitter", link: "#" },
      { icon: "fa-instagram", link: "#" },
      { icon: "fa-dribbble", link: "#" },
    ],
  },
];



export default Home
