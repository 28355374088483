// src/pages/Dentures.jsx
import React from "react";

const Dentures = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-100 to-white text-gray-800">
      <div className="container mx-auto py-12 px-4">
        {/* Header Section */}
        <div className="text-center mb-10">
          <h1 className="text-5xl font-extrabold text-blue-600 animate-pulse">
            Rediscover Your Confidence with <span className="text-pink-600">Dentures</span>
          </h1>
          <p className="text-lg text-gray-600 mt-4 animate-fadeIn">
            Embrace comfort and functionality with our expertly crafted denture solutions.
          </p>
        </div>

        {/* About Dentures */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">What Are Dentures?</h2>
          <p className="text-gray-700 leading-relaxed">
            Dentures are custom-made dental appliances designed to replace missing teeth and restore your smile. 
            Whether you need partial or full dentures, our solutions ensure optimal comfort, durability, and aesthetics.
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li>Enhanced chewing and speaking abilities</li>
            <li>Improved facial structure and aesthetics</li>
            <li>Tailored fit for maximum comfort</li>
          </ul>
        </div>

        {/* Benefits Section */}
        <div className="bg-gradient-to-br from-blue-100 to-pink-50 shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Why Choose Our Dentures?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <i className="fa fa-smile text-blue-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Natural Appearance</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-thumbs-up text-blue-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Comfortable Fit</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-leaf text-blue-500 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Eco-Friendly Materials</p>
            </div>
          </div>
        </div>

        {/* Types of Dentures */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Types of Dentures We Offer</h2>
          <p className="text-gray-700 leading-relaxed">
            Explore our range of denture solutions tailored to your unique needs:
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li><span className="font-bold">Full Dentures:</span> For complete tooth replacement in the upper or lower jaw.</li>
            <li><span className="font-bold">Partial Dentures:</span> To fill gaps while preserving remaining teeth.</li>
            <li><span className="font-bold">Flexible Dentures:</span> Lightweight and comfortable, ideal for sensitive gums.</li>
          </ul>
        </div>

        {/* Call-to-Action Section */}
        <div className="bg-gradient-to-r from-blue-600 to-pink-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold animate-bounce">Ready to Restore Your Smile?</h2>
          <p className="mt-4 text-lg">
            Schedule a consultation today and discover the perfect denture solution for you.
          </p>
          <button className="mt-6 bg-white text-blue-600 font-bold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition">
            Book Your Appointment
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dentures;
