// src/pages/OralHygiene.jsx
import React from "react";

const OralHygiene = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-200 to-pink-200 text-gray-800">
      <div className="container mx-auto py-12 px-4">
        {/* Header Section */}
        <div className="text-center mb-10">
          <h1 className="text-5xl font-extrabold text-blue-600 animate-pulse">
            Elevate Your Wellness with <span className="text-pink-600">Oral Hygiene</span>
          </h1>
          <p className="text-lg text-gray-600 mt-4 animate-fadeIn">
            Discover the key to a healthy, radiant smile through expert oral hygiene care.
          </p>
        </div>

        {/* About Oral Hygiene */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">What Is Oral Hygiene?</h2>
          <p className="text-gray-700 leading-relaxed">
            Oral hygiene refers to the practice of maintaining your teeth, gums, and mouth clean to prevent dental issues and ensure overall health. 
            With proper oral care, you can protect your smile and improve your quality of life.
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li>Prevents cavities and gum diseases</li>
            <li>Boosts overall health and confidence</li>
            <li>Ensures fresh breath and a bright smile</li>
          </ul>
        </div>

        {/* Benefits Section */}
        <div className="bg-gradient-to-br from-blue-100 to-pink-50 shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Why Prioritize Oral Hygiene?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <i className="fa fa-tooth text-blue-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Healthier Teeth & Gums</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-smile-beam text-pink-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Brighter Smile</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-heartbeat text-red-500 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Improves Overall Health</p>
            </div>
          </div>
        </div>

        {/* Best Practices Section */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Best Practices for Oral Hygiene</h2>
          <p className="text-gray-700 leading-relaxed">
            Adopting good oral hygiene habits is simple yet crucial for long-term dental health. Here are some essential tips:
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li>Brush your teeth twice a day with fluoride toothpaste</li>
            <li>Floss daily to remove plaque and food particles</li>
            <li>Limit sugary foods and beverages</li>
            <li>Visit your dentist regularly for check-ups and cleanings</li>
          </ul>
        </div>

        {/* Call-to-Action Section */}
        <div className="bg-gradient-to-r from-blue-600 to-pink-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold animate-bounce">Ready to Achieve Optimal Oral Health?</h2>
          <p className="mt-4 text-lg">
            Book an appointment today and start your journey toward a healthier smile.
          </p>
          <button className="mt-6 bg-white text-blue-600 font-bold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition">
            Schedule a Visit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OralHygiene;
