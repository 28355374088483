// src/pages/CosmeticDentistry.jsx
import React from "react";

const CosmeticDentistry = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-100 to-white text-gray-800">
      <div className="container mx-auto py-12 px-4">
        {/* Header Section */}
        <div className="text-center mb-10">
          <h1 className="text-5xl font-extrabold text-blue-600 animate-pulse">
            Enhance Your Smile with <span className="text-pink-600">Cosmetic Dentistry</span>
          </h1>
          <p className="text-lg text-gray-600 mt-4 animate-fadeIn">
            Achieve the perfect smile with our wide range of cosmetic dental treatments tailored just for you.
          </p>
        </div>

        {/* About Cosmetic Dentistry */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">What is Cosmetic Dentistry?</h2>
          <p className="text-gray-700 leading-relaxed">
            Cosmetic dentistry focuses on improving the appearance of your smile through various advanced techniques.
            From teeth whitening to complete smile makeovers, we ensure you look and feel your best.
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li>Boosts confidence with a radiant smile</li>
            <li>Custom treatments for natural, long-lasting results</li>
            <li>Combines art and science for aesthetic perfection</li>
          </ul>
        </div>

        {/* Popular Treatments */}
        <div className="bg-gradient-to-br from-blue-100 to-pink-50 shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Our Popular Cosmetic Dentistry Treatments</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <i className="fa fa-star text-blue-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Teeth Whitening</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-smile text-pink-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Porcelain Veneers</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-tooth text-blue-500 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Dental Bonding</p>
            </div>
          </div>
        </div>

        {/* Smile Makeover Section */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Smile Makeover</h2>
          <p className="text-gray-700 leading-relaxed">
            A comprehensive smile makeover involves a combination of cosmetic treatments designed to enhance every aspect of your smile. 
            Our expert team uses the latest techniques to craft a smile that complements your unique features.
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li><span className="font-bold">Customized Plan:</span> Tailored treatments to meet your goals</li>
            <li><span className="font-bold">Advanced Technology:</span> Ensures precision and comfort</li>
            <li><span className="font-bold">Natural Results:</span> Achieve a radiant, confident smile</li>
          </ul>
        </div>

        {/* Call-to-Action Section */}
        <div className="bg-gradient-to-r from-blue-600 to-pink-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold animate-bounce">Ready to Transform Your Smile?</h2>
          <p className="mt-4 text-lg">
            Contact us today to learn more about our cosmetic dentistry services and start your journey to a stunning smile.
          </p>
          <button className="mt-6 bg-white text-blue-600 font-bold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition">
            Schedule a Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default CosmeticDentistry;
