// src/pages/DentalSurgery.jsx
import React from "react";

const DentalSurgery = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-100 to-white text-gray-800">
      <div className="container mx-auto py-12 px-4">
        {/* Header Section */}
        <div className="text-center mb-10">
          <h1 className="text-5xl font-extrabold text-blue-600 animate-pulse">
            Advanced Dental Surgery <span className="text-pink-600">Solutions</span> 
          </h1>
          <p className="text-lg text-gray-600 mt-4 animate-fadeIn">
            Achieve optimal oral health and functionality with our expert dental surgery services.
          </p>
        </div>

        {/* About Dental Surgery */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">What is Dental Surgery?</h2>
          <p className="text-gray-700 leading-relaxed">
            Dental surgery encompasses a variety of procedures aimed at treating oral health issues, restoring functionality, 
            and enhancing aesthetics. From wisdom tooth extractions to complex reconstructive surgeries, our team ensures the 
            highest standard of care.
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li>Pain-free procedures with advanced technology</li>
            <li>Improves overall oral health and quality of life</li>
            <li>Tailored treatment plans for individual needs</li>
          </ul>
        </div>

        {/* Benefits Section */}
        <div className="bg-gradient-to-br from-blue-100 to-pink-50 shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Why Opt for Dental Surgery?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <i className="fa fa-tooth text-blue-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Improved Oral Functionality</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-smile text-pink-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Enhanced Aesthetics</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-heartbeat text-red-500 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Boosted Oral Health</p>
            </div>
          </div>
        </div>

        {/* Types of Dental Surgeries */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Types of Dental Surgeries We Offer</h2>
          <p className="text-gray-700 leading-relaxed">
            Our dental surgery services cover a wide range of procedures designed to address diverse oral health needs:
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li><span className="font-bold">Wisdom Tooth Removal:</span> Safe and pain-free extractions.</li>
            <li><span className="font-bold">Dental Implants:</span> Permanent solutions for missing teeth.</li>
            <li><span className="font-bold">Jaw Alignment Surgery:</span> Corrective procedures for bite issues.</li>
            <li><span className="font-bold">Reconstructive Surgery:</span> Repairing damage from injury or disease.</li>
          </ul>
        </div>

        {/* Call-to-Action Section */}
        <div className="bg-gradient-to-r from-blue-600 to-pink-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold animate-bounce">Your Oral Health Matters</h2>
          <p className="mt-4 text-lg">
            Schedule a consultation today and let our experts provide the best surgical care for your needs.
          </p>
          <button className="mt-6 bg-white text-blue-600 font-bold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition">
            Book Your Appointment
          </button>
        </div>
      </div>
    </div>
  );
};

export default DentalSurgery;
