// src/pages/DentalImplants.jsx
import React from "react";

const DentalImplants = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-white text-gray-800">
      <div className="container mx-auto py-12 px-4">
        {/* Header Section */}
        <div className="text-center mb-10">
          <h1 className="text-5xl font-extrabold text-blue-700 animate-pulse">
            Transform Your Smile with <span className="text-pink-600">Dental Implants</span>
          </h1>
          <p className="text-lg text-gray-600 mt-4 animate-fadeIn">
            Experience the best in modern dentistry with our advanced implant solutions. 
          </p>
        </div>

        {/* About Dental Implants */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">What Are Dental Implants?</h2>
          <p className="text-gray-700 leading-relaxed">
            Dental implants are a state-of-the-art solution for missing teeth, offering unparalleled durability, 
            comfort, and aesthetics. Unlike traditional options, implants are designed to fuse with your jawbone, 
            providing a stable and permanent foundation for replacement teeth.
          </p>
          <ul className="list-disc ml-6 mt-4 text-gray-600">
            <li>Customized to seamlessly match your natural teeth</li>
            <li>Promotes oral health by preventing bone loss</li>
            <li>Enhances confidence and restores functionality</li>
          </ul>
        </div>

        {/* Benefits Section */}
        <div className="bg-gradient-to-br from-pink-100 to-blue-50 shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-pink-600 mb-4 text-center">Why Choose Dental Implants?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <i className="fa fa-smile-o text-blue-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Natural Look & Feel</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-thumbs-up text-pink-600 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Long-Lasting Durability</p>
            </div>
            <div className="flex flex-col items-center">
              <i className="fa fa-heart text-red-500 text-4xl mb-4"></i>
              <p className="text-gray-700 text-center">Improves Oral Health</p>
            </div>
          </div>
        </div>

        {/* Procedure Section */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
          <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Our Dental Implant Procedure</h2>
          <p className="text-gray-700 leading-relaxed">
            Our dental implant process is designed with your comfort and convenience in mind. 
            Here’s what to expect:
          </p>
          <ol className="list-decimal ml-6 mt-4 text-gray-600">
            <li>Initial consultation and personalized treatment plan</li>
            <li>Painless placement of the implant into the jawbone</li>
            <li>Healing period to allow osseointegration</li>
            <li>Attachment of a custom-designed crown or prosthetic</li>
          </ol>
        </div>

        {/* Call-to-Action Section */}
        <div className="bg-gradient-to-r from-blue-600 to-pink-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold animate-bounce">Ready to Transform Your Smile?</h2>
          <p className="mt-4 text-lg">
            Contact us today for a consultation and take the first step toward a brighter, healthier smile.
          </p>
          <button className="mt-6 bg-white text-blue-600 font-bold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition">
            Book an Appointment
          </button>
        </div>
      </div>
    </div>
  );
};

export default DentalImplants;
