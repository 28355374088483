
import React from 'react';

const Footer = () => {
  return (
    <>
      {/* Footer Section Begin */}
      <footer className="bg-gray-900 text-gray-300">
        {/* Top Section */}
        <div className="bg-gray-800 py-10">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between items-center gap-6">
              {/* Logo */}
              <div className="flex-shrink-0">
                <a href="#" className="block">
                  <img
                    src="img/logo.png"
                    alt="Logo"
                    className="h-20 w-auto md:h-16 sm:h-12 sm:w-auto object-contain"
                  />
                </a>
              </div>

              {/* Social Media Links */}
              <div className="flex space-x-4 text-2xl sm:justify-center sm:items-center">
                <a
                  href="#"
                  className="hover:text-[#5876d6] transition"
                  aria-label="Facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="#"
                  className="hover:text-[#5876d6] transition"
                  aria-label="Twitter"
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="#"
                  className="hover:text-[#5876d6] transition"
                  aria-label="Instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
                <a
                  href="#"
                  className="hover:text-[#5876d6] transition"
                  aria-label="Dribbble"
                >
                  <i className="fa fa-dribbble"></i>
                </a>
              </div>

            </div>
          </div>
        </div>

        {/* Main Section */}
        <div className="container mx-auto px-4 py-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Company Section */}
            <div>
              <h5 className="text-lg font-bold mb-4 text-[#5876d6] hover:cursor-pointer hover:
              
              
              
              
              
              
              
              transition">Company</h5>
              <ul className="space-y-2">
                <li>
                  <a href="/about" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/services" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                    Services
                  </a>
                </li>
                <li>
                  <a href="/testimonials" className="transition text-gray--800 hover:cursor-pointer hover:text-[#d6589d]">
                    Testimonials
                  </a>
                </li>
                <li>
                  <a href="/contact" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                    Contact
                  </a>
                </li>
                {/* <li>
                  <a href="#" className="hover:text-[#5876d6] transition">
                    News
                  </a>
                </li> */}
              </ul>
            </div>

            {/* Quick Links Section */}
            <div>
              <h5 className="text-lg font-bold mb-4 text-[#5876d6] hover:cursor-pointer hover:text-[#d6589d]">
                Our Services
              </h5>
              {/* <ul className="space-y-2">
                <li>
                  <a href="#" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                  Dental Implemants
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                  Dentures
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                  Cosmatic Dentistry
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                  Oral Hygiene
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray--800 hover:cursor-pointer hover:text-[#d6589d] transition">
                  Dental Surgery
                  </a>
                </li>
              </ul> */}
              <ul className="space-y-2">
                <li>
                  <p className="text-gray--800 text-white hover:cursor-pointer hover:text-[#d6589d] transition">
                    Dental Implants
                  </p>
                </li>
                <li>
                  <p className="text-gray--800 text-white hover:cursor-pointer hover:text-[#d6589d] transition">
                    Dentures
                  </p>
                </li>
                <li>
                  <p  className="text-gray--800 text-white hover:cursor-pointer hover:text-[#d6589d] transition">
                    Cosmetic Dentistry
                  </p>
                </li>
                <li>
                  <p  className="text-gray--800 text-white hover:cursor-pointer hover:text-[#d6589d] transition">
                    Oral Hygiene
                  </p>
                </li>
                <li>
                  <p  className="text-gray--800 text-white hover:cursor-pointer hover:text-[#d6589d] transition">
                    Dental Surgery
                  </p>
                </li>
              </ul>

            </div>

            {/* Contact Section */}
            <div>
              <h5 className="text-lg font-bold mb-4 text-[#5876d6] hover:cursor-pointer hover:text-[#d6589d]">
                Contact Us
              </h5>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <i className="fa fa-map-marker mr-3 mt-1 text-[#5876d6] hover:cursor-pointer hover:text-[#d6589d]"></i>
                  <span className='hover:text-[#d6589d] hover:cursor-pointer duration-200'>
                    Plot No. 12/A, Mansha Square, Near S-Mart, Jafar Nagar, Nagpur - 440013
                  </span>
                </li>
                <li className="flex items-center">
                  <i className="fa fa-phone mr-3 text-[#5876d6] hover:cursor-pointer hover:text-[#d6589d]"></i>
                  <a
                    href="tel:+91 72767 09059"
                    className="transition hover:cursor-pointer hover:text-[#d6589d]"
                  >
                    +91 72767 09059
                  </a>
                </li>
                <li className="flex items-center">
                  <i className="fa fa-envelope mr-3 text-[#5876d6] hover:cursor-pointer hover:text-[#d6589d]"></i>
                  <a
                    href="mailto:mahalaxmi@dentalclinic.in"
                    className="hover:text-[#d6589d] transition"
                  >
                    support@ivorysmilesdental.in
                  </a>
                </li>
              </ul>
            </div>

            {/* Map Section */}
            <div>
              <h5 className="text-lg font-bold mb-4 text-[#5876d6] hover:cursor-pointer hover:text-[#d6589d]">Find Us</h5>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.2364318000305!2d79.0682434!3d21.182764699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c182a3552421%3A0xf73cfe8848701113!2sIvory%20Smiles%20Dental%20Clinic%20Orthodontic%20Center!5e0!3m2!1sen!2sin!4v1733834162456!5m2!1sen!2sin"
                className="w-full h-40 rounded-md shadow-lg"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="bg-gray-800 py-4">
          <div className="container mx-auto px-4 flex flex-wrap items-center justify-between text-sm text-gray-400">
            <p className='text-white'>© 2024
              Ivory Smiles Dental Clinic Orthodontic Center, Nagpur</p>
            <p>
              Design & Developmed by &nbsp;
              <span>
                <a
                  href="https://kodekalp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#d6589d] hover:text-blue-500 transition"
                >
                  KodeKalp Global Technologies.
                </a>
              </span>
            </p>
            <ul className="flex space-x-4">
              <li>
                <a href="#" className="hover:text-[#5876d6] transition">
                  Terms & Use
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-[#5876d6] transition">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

      </footer>
    </>
  );
};

export default Footer;
