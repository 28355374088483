import React from 'react'

const Testimonials = () => {
    return (
        <>
            <section
                className="py-20 bg-cover bg-center"
                style={{ backgroundImage: "url(img/gallery/gallery-3.jpg)" }}
            >
                <div className="container mx-auto text-center">
                    <h2 className="text-4xl font-bold text-white  duration-200 cursor-pointer"> Testimonials </h2>
                    {/* <div className="mt-4">
            <a href="./index.html" className="text-gray-600 hover:text-lime-600">
              Home
            </a>{" "}
            <span className="text-gray-400 mx-2">/</span>{" "}
            <span className="text-lime-600">Contact</span>
          </div> */}
                </div>
            </section>
            <div className='text-center mt-10'>
                <h2 className="text-[#d6589d] text-sm uppercase font-bold  hover:cursor-pointer">Testimonials</h2>

                <h3 className="text-4xl font-bold text-[#5876d6] hover:cursor-pointer text-center mt-2">
                    What Our Patient Say
                </h3>
            </div>
            <section className="latest py-16 bg-gray-50">
                <div className="container mx-auto px-4">




                    {/* Testimonials Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {/* Testimonial 1 */}
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h5 className="text-xl font-semibold text-gray-800">
                                <a href="#" className="hover:text-blue-600 transition">
                                    “Amazing results and natural glow!”
                                </a>
                            </h5>
                            <p className="text-gray-600 mt-4">
                                The team at Ivory Smiles Dental Clinic exceeded my expectations! Their attention to detail and personalized care gave me a smile that looks completely natural and radiant. I couldn’t be happier with the results.
                            </p>

                            {/* Stars */}
                            <div className="flex items-center mt-4">
                                {[...Array(5)].map((_, i) => (
                                    <i key={i} className="fa fa-star text-blue-500"></i>
                                ))}
                            </div>
                            {/* Author Info */}
                            <ul className="flex items-center space-x-4 mt-4 text-gray-500">
                                <li className="flex items-center">
                                    <img
                                        src="img/blog/blog-author.jpg"
                                        alt="Author"
                                        className="w-8 h-8 rounded-full mr-2"
                                    />
                                    Nagraj Pandey
                                </li>
                                <li>Dec 06, 2019</li>
                            </ul>
                        </div>

                        {/* Testimonial 2 */}
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h5 className="text-xl font-semibold text-gray-800">
                                <a href="#" className="hover:text-blue-600 transition">
                                    “Top-notch service with visible improvements!”
                                </a>
                            </h5>
                            <p className="text-gray-600 mt-4">
                                From the moment I walked in, I felt at ease. The staff is incredibly welcoming, and the level of expertise displayed throughout my treatment was truly impressive.
                            </p>

                            {/* Stars */}
                            <div className="flex items-center mt-4">
                                {[...Array(4)].map((_, i) => (
                                    <i key={i} className="fa fa-star text-blue-500"></i>
                                ))}
                                <i className="fa fa-star text-gray-300"></i>
                            </div>
                            {/* Author Info */}
                            <ul className="flex items-center space-x-4 mt-4 text-gray-500">
                                <li className="flex items-center">
                                    <img
                                        src="img/blog/blog-author.jpg"
                                        alt="Author"
                                        className="w-8 h-8 rounded-full mr-2"
                                    />
                                    Bhushan Kumar
                                </li>
                                <li>Jan 15, 2020</li>
                            </ul>
                        </div>

                        {/* Testimonial 3 */}
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h5 className="text-xl font-semibold text-gray-800">
                                <a href="#" className="hover:text-blue-600 transition">
                                    “Fantastic experience from start to finish!”
                                </a>
                            </h5>
                            <p className="text-gray-600 mt-4">
                                The care and precision demonstrated by the team were exceptional. Every step of my treatment was explained thoroughly, and the results speak for themselves. A truly outstanding experience at Ivory Smiles Dental Clinic.
                            </p>

                            {/* Stars */}
                            <div className="flex items-center mt-4">
                                {[...Array(5)].map((_, i) => (
                                    <i key={i} className="fa fa-star text-blue-500"></i>
                                ))}
                            </div>
                            {/* Author Info */}
                            <ul className="flex items-center space-x-4 mt-4 text-gray-500">
                                <li className="flex items-center">
                                    <img
                                        src="img/blog/blog-author.jpg"
                                        alt="Author"
                                        className="w-8 h-8 rounded-full mr-2"
                                    />
                                    Sanjib Das
                                </li>
                                <li>Feb 20, 2020</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='flex justify-center my-5'>
                                <a 
                                    href="/testimonials" 
                                    className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-500 transition">
                                    View all testimonials
                                </a>
                            </div> */}
                </div>
            </section>
        </>
    )
}

export default Testimonials
